import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/EnquiryRequest";


export function allEnquiryRequestsRequest(query:string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.URL_ALL_ENQUIRY_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export async function getEnquiryRequestDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_ENQUIRY_REQUEST_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

