import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/P2pEnquiryRequest";


export function allP2pEnquiryRequestsRequest(query:string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.URL_ALL_P2P_ENQUIRY_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export async function getP2pEnquiryRequestDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_P2P_ENQUIRY_REQUEST_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

