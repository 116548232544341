import { FormField } from 'src/helpers';
import * as Yup from 'yup';

export interface IEnquiryRequest {
    _id?: string,
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    category: string;
    queryType: string;
    description: string;
    createdAt: string;
}

export const initEnquiryRequest: IEnquiryRequest = {
    _id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    category: "",
    queryType: "",
    description: "",
    createdAt: "",
};

export const ENQUIRY_REQUESTS_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Name',
            maxLength: 150
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'lastName',
            maxLength: 150
        },
    },
    {
        label: 'Phone',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone Number',
            regex: /[^0-9]+/g,
            maxLength: 10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150
        },
    },
    {
        label: 'Category',
        dataKey: 'category',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'category',
        },
    },
    {
        label: 'QueryType',
        dataKey: 'queryType',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Query Type',
            maxLength: 50
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'description',
            maxLength: 50
        },
    },
    {
        label: 'Date',
        dataKey: 'createdAt',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'createdAt',
        },
    },
];


export const EnquiryFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'First name is required')
        .required('First name is required'),
    lastName: Yup.string()
        .min(1, 'Last name is required')
        .required('Last name is required'),
    phoneNumber: Yup.string()
        .length(10, 'Phone number must be exactly 10 digits')
        .matches(/^\d+$/, 'Phone number must be numeric')
        .required('Phone number is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    category: Yup.string()
        .min(1, 'Category is required')
        .required('Category is required'),
    queryType: Yup.string()
        .min(1, 'Query type is required')
        .required('Query type is required'),
    description: Yup.string()
        .min(1, 'Description is required')
        .required('Description is required'),
});