import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllProjectsMain from './AllP2pEnquiryRequestMain'
import EditProjectMain from './EditP2pEnquiryRequestsMain'
import ViewProjectMain from './ViewP2pEnquiryRequestMain'

type Props = {}

const P2pEnquiryRequestsRoute = (props: Props) => {

    const PROJECTS_ROUTES = APP_ROUTES.enquiryRequests.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllProjectsMain />} />
                <Route path={PROJECTS_ROUTES.all.route} element={<AllProjectsMain />} />
                <Route path={PROJECTS_ROUTES.edit.route + "/:id"} element={<EditProjectMain />} />
                <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ViewProjectMain />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.projects.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default P2pEnquiryRequestsRoute